import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class SocialMedia extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Social Media Marketing Company in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content="Grow your brand with expert social media marketing.BicAds Digital offers SEO-friendly strategies, content, ads & influencer marketing to boost leads!" />
	<link rel="canonical" href="https://bicads.com/social-media-marketing-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Social Media Marketing' subtitle='Social Media Marketing' BkImg='/assets/images/bicads/Banner/Social-Media-Marketing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Social Media Marketing</h3>

            <h2 className='px-5 '>Your Business Should Be Available Where Your Audience Are</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceFirst'>
                        <h1 className='mainTextH4'>SMM – Social Media Marketing Company In Pune and Mumbai (India) </h1>

                        <p className=' mainTextP'>Take your brand’s visibility and engagement to the next level with powerful Social Media Marketing (SMM) strategies! With billions of users actively engaging on platforms like Facebook, Instagram, LinkedIn, and Twitter, social media has become a game-changer for businesses. If you’re not leveraging it, you’re missing out on an incredible opportunity to connect with your audience and drive brand growth.

</p>  
                        <p  className=' mainTextP'>At BicAds, we go beyond just posting content—we create impact-driven social media campaigns that resonate with your audience. Whether you’re looking to increase engagement, generate leads, or build a loyal community, our team crafts data-backed strategies tailored to your business goals. With creative content, targeted promotions, and real-time analytics, we ensure your brand stays ahead in the ever-evolving digital landscape.

</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Social-Media-Marketing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>Our Social Media Marketing Solutions</h3>

            <h2 className='px-5 '>How Social Media Marketing Can Help You

</h2>

            <p>Social media marketing is loaded with advantages, so you want to use it. With just a few hours each week, you can boost your brand awareness and attract more traffic. Using a social media marketing company can help you get started.</p>

 
            <Row className='goodWebsite d-none'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                     
                <Row >
                    
                    <Col lg="9">
                     <Row >
                 

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Tailored Social Media Strategy
 </h4>

                        <p className=' mainTextP'>We create personalized social media strategies aligned with your brand’s objectives and audience preferences. Our data-centric approach involves analyzing industry trends, monitoring competitor activities, and identifying opportunities to enhance your digital presence. Whether your goal is to boost brand visibility, generate leads, or increase sales, our customized plans deliver measurable outcomes, fostering long-term business growth.

</p>  
                        </div>
                                     </Col>
                     
                       
                      
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Content Creation & Scheduling

 </h4>

                        <p className=' mainTextP'>Our creative content strategy involves producing visually appealing and platform-optimized posts that connect with your audience. Using market insights and trend analysis, we ensure your brand’s message resonates effectively. From attention-grabbing images and videos to interactive stories and reels, we craft content that maximizes reach and engagement. With strategic scheduling, your posts are published at the best times for optimal visibility and interaction.


</p>  
                        </div>
                                     </Col>
                     

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Social Media Advertising
 </h4>

                        <p className=' mainTextP'>We design and execute data-driven social media ad campaigns tailored to reach your target audience. Through advanced targeting, compelling visuals, and persuasive ad copy, we ensure your brand stands out. Whether it’s on Facebook, Instagram, LinkedIn, or other platforms, our campaigns drive qualified leads, boost conversions, and provide a strong return on investment. Continuous monitoring and optimization further maximize your ad performance.

</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Influencer Collaborations
 </h4>

                        <p className=' mainTextP'>Enhance brand credibility and expand your reach with impactful influencer partnerships. We connect your brand with influencers who align with your industry and audience demographics. Through authentic endorsements, product reviews, and engaging collaborations, we amplify your brand’s presence and build social proof. Influencer marketing is a powerful tool to drive conversions, foster brand loyalty, and establish trust.



</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Community Engagement
 </h4>

                        <p className=' mainTextP'>Building strong relationships with your audience is key to sustainable growth. We actively manage your social media presence by engaging with followers, responding to comments and messages, and encouraging conversations. Our interactive approach creates a sense of community around your brand, fostering loyalty and encouraging user-generated content. Authentic engagement strengthens your brand’s reputation and maintains audience interest.





</p>  
                        </div>
                                     </Col>
                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Performance Tracking & Optimization </h4>

                        <p className=' mainTextP'>We track and analyze your social media performance using advanced analytics tools. Metrics such as engagement rates, click-through rates, and audience behavior provide valuable insights. Based on this data, we make informed adjustments to optimize your strategy for maximum effectiveness. Our detailed reports offer actionable recommendations, ensuring continuous improvement and a higher return on investment.</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default SocialMedia;