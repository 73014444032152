import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
import { NavLink } from 'react-router-dom';

 class WebsiteDesign extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <meta charSet="utf-8" />
             <title>Website Development Company | Bicads Digital </title>
             <meta name="description" content="Bicads Digital offers custom web design, e-commerce solutions & SEO-friendly websites in Pune & Mumbai. Get a mobile-optimized website for growth!" />

             <link rel="canonical" href="https://bicads.com/website-development-company/" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Website ' subtitle='Design' BkImg='/assets/images/bicads/Website-Banner.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

     
        <div className='ServiceHeading  '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Website Development </h3>

            <h2 className='px-5 '>What makes a company great? <br></br> That’s simple. It’s the people who work here.</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className="py-4 ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond'>
                        <h1 className='mainTextH4'>Website Development Company in Pune and Mumbai (India)  </h1>

                        <p className=' mainTextP'>Create Powerful Websites That Deliver Results

</p>  
                        <p className=' mainTextP'>Want a website that not only looks impressive but also works seamlessly? Whether you are a growing startup or a well-established brand, having a strong online presence is essential. Our experienced team designs custom websites that reflect your brand identity, offering an excellent user experience and smooth functionality. From responsive designs to secure e-commerce platforms, we build websites that engage visitors and drive measurable results.

</p>
<p className=' mainTextP'>Fun Fact: A well-designed website can significantly boost conversion rates!

</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Website-image.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>
            
  {/* -------------------------------------------------------------------------- */}

 <div className='ServiceHeading d-none '>
                <Container className=" py-5   text-center">
                <h3 className='mainTextH1'>  Our Process </h3>

            <h2 className='px-5 '> We Use An Established SDLC Process <br></br>For Our Design And Development Processs


</h2>

            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-5.png" alt={config.SITE_NAME} fluid   />
                 <h5>Understand Requirement & Analyse</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-6.png" alt={config.SITE_NAME} fluid   />
                    <h5>Design & Architecture</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-7.png" alt={config.SITE_NAME} fluid   />
                    <h5>Implementation & Coding</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-8.png" alt={config.SITE_NAME} fluid   />
                    <h5>Testing</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Good Website </h3>

            <h2 className='px-5 '>A Well Designed & Performing Website Should Have</h2>

            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

       
       
<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                     <Row >
                    
                        <Col lg="9">
                       
                      
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Custom Website Design & Development
 </h4>

                        <p className=' mainTextP'>We offer tailored website design and development services that reflect your brand identity and align with your business goals. Our experienced designers and developers focus on user experience (UX) and user interface (UI) design to create visually appealing, easy-to-navigate websites. With features customized to your industry, we ensure your site captures your brand’s essence and drives engagement.

</p>  
                        </div>
                                     </Col>
                

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Mobile-Optimized Designs

 </h4>

                        <p className=' mainTextP'>Enhance user experience across devices with our responsive, mobile-friendly designs. We ensure your website adapts seamlessly to different screen sizes, offering a smooth browsing experience on smartphones, tablets, and desktops. With mobile optimization, your site will load quickly, retain visitors, and improve search engine rankings.



</p>  
                        </div>
                        </Col>
                  
                       
                       

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        E-Commerce Solutions
 </h4>

                        <p className=' mainTextP'>Boost online sales with our scalable e-commerce website solutions. From secure payment gateways and user-friendly product catalogs to inventory management and seamless checkout experiences, we create platforms that make shopping easy. Our e-commerce websites are designed to maximize conversions and support your business growth with built-in SEO features.

</p>  
                        </div>
                                     </Col>
                   
                       
                       
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>SEO & Performance Optimization

 </h4>

                        <p className=' mainTextP'>Get found online with our performance-optimized websites. We apply the latest search engine optimization (SEO) techniques, focusing on site speed, keyword integration, and content optimization. By implementing clean code and responsive design, we ensure your website ranks higher on search engines, attracts organic traffic, and enhances user satisfaction.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'> CMS Integration </h4>

                        <p className=' mainTextP'>Manage your website effortlessly with our content management system (CMS) integration. Whether you prefer WordPress, Shopify, or a custom CMS, we provide intuitive platforms that simplify content updates, product management, and analytics tracking. Our user-friendly CMS solutions give you full control over your website’s content without needing technical expertise.





</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Ongoing Maintenance & Support
                        </h4>

                        <p className=' mainTextP'>Keep your website running smoothly with our reliable maintenance and support services. We offer regular updates, security patches, and performance monitoring to prevent downtime and ensure optimal performance. Our dedicated support team is available to troubleshoot issues, update content, and provide insights to continuously improve your site’s functionality and user experience.







</p>  
                        </div>
                                     </Col>

                                     </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>     
                    </Row>
                </Container>
            </div>
            </div>


 
        {/* -------------------------------------------------------------------------- */}

             <Footer/>
        </div>
    );
} 
}
export default WebsiteDesign;