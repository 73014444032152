import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class GraphicDesign extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Best Graphic Designing Company in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content="Get professional graphic design services with BicAds Digital in Pune & Mumbai! We design logos, branding, social media graphics, brochures & more." />
	<link rel="canonical" href="https://bicads.com/graphic-designing-company" />
	
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Graphic Design' subtitle='Design' BkImg='/assets/images/bicads/Banner/Graphic-Designing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Graphic Design</h3>

            <h2 className='px-5 '>Bring Your Brand to Life with Exceptional Design

</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceFirst'>
                        <h1 className='mainTextH4'>Graphic Design Company In Pune and Mumbai (India)

 </h1>

                        <p className=' mainTextP'>Looking for creative and impactful designs that captivate your audience? Our expert graphic designers specialize in crafting visually compelling designs that communicate your brand story effectively. From logos and branding to marketing collaterals and social media visuals, we provide end-to-end graphic design solutions that help your business stand out in a competitive market.



</p>  

<p className=' mainTextP'>Fun Fact: Visual content is processed 60,000 times faster than text, making design a powerful marketing tool!



</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Graphic-Designing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>Design Matters</h3>

            <h2 className='px-5 '>Good Graphic Design For A Business
</h2>

            <p>A professional graphic designer can produce effective marketing materials like brochures, business cards, leaflets, banners, and mobile apps in addition to designing a logo. It combines photography, illustration, motion graphics to create attractive images which can capture the audience's attention, convert them into sales, and open up new channels of commerce.</p>

 
            <Row className='goodWebsite d-none'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Logo & Brand Identity Design

 </h4>

                        <p className=' mainTextP'>We create unique logo designs and cohesive brand identities that reflect your company's values and vision. Our designs are crafted to ensure brand recognition and create a strong visual presence. From logo conceptualization to complete brand identity development, we provide designs that resonate with your target audience and set you apart in a competitive market.

</p>  
                        </div>
                  </Col>
                       
                        
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Marketing Collaterals


 </h4>

                        <p className=' mainTextP'>Enhance your brand visibility with custom-designed marketing materials. Whether it’s brochures, flyers, banners, or business cards, we ensure your brand message is clear and impactful. Our print and digital collateral designs maintain consistency across all platforms, providing a professional and memorable brand experience.



</p>  
                        </div>
                                     </Col>
                     

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Social Media Graphics

 </h4>

                        <p className=' mainTextP'>Boost your digital presence with visually appealing social media graphics. We design platform-specific visuals that grab attention and increase engagement. From Instagram posts to LinkedIn banners, our designs are optimized for mobile and desktop viewing, helping you effectively connect with your audience and drive conversions.

</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Website Graphics

 </h4>

                        <p className=' mainTextP'>Make your website visually engaging with our custom graphic designs. We create banners, icons, illustrations, and UI elements that enhance user experience and reflect your brand identity. By incorporating responsive design and visually consistent elements, we ensure your website captures visitors' attention and encourages interaction.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Infographics

 </h4>

                        <p className=' mainTextP'>Simplify complex data with eye-catching infographics that effectively convey your message. Our designs combine clear visuals and concise information, making them ideal for social media, presentations, blog posts, and reports. With strategic use of brand colors and imagery, we create infographics that enhance audience understanding and boost content engagement.





</p>  
                        </div>
                                     </Col>
                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Packaging Design


 </h4>

                        <p className=' mainTextP'>Stand out on shelves with innovative packaging designs that capture consumer interest. Our team develops product packaging that is both functional and visually striking. We focus on branding consistency, user-friendly design, and aesthetic appeal to ensure your products leave a lasting impression and drive sales.




</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default GraphicDesign;