import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class Ecommerce extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>E-commerce Website Development in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content="Get a custom eCommerce site with BicAds Digital! SEO-friendly, mobile-ready stores on Shopify, WooCommerce & Magento with secure payments & easy management." />
	<link rel="canonical" href="https://bicads.com/e-commerce-website-development-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Website ' subtitle='Design' BkImg='/assets/images/bicads/Banner/Ecommerce.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> E-commerce Website Development</h3>

            <h2 className='px-5 '>Got A Passion?  <br></br> Take It Online With Us!!</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" py-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond '>
          <h1 className='mainTextH4'>Empower Your Business with Custom E-commerce Solutions </h1>

                        <p className='mainTextP'>Want to take your business online or enhance your current store? Our E-commerce website development services in Pune and Mumbai provide tailored solutions to build a strong online presence and drive sales. We offer user-friendly designs, seamless payment integrations, and reliable management systems to ensure your customers enjoy a hassle-free shopping experience.

</p>  

                       
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Ecommerce.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Services 24/7 </h3>

            <h2 className='px-5 '>Sell Your Products & Services 24/7</h2>
            <p>Setting up an e-commerce website is a fantastic method to sell your items and services 24 hours a day, seven days a week. You may have up to 10 items, no up-front expenses or contracts, if you wish to begin an online business. An ecommerce website may be developed using the proper platform. You may sell your items and services to customers worldwide using an automated sales system, saving time and money.</p>

 
            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                    
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Custom E-commerce Solutions
 </h4>

                        <p className=' mainTextP'>Develop a fully personalized e-commerce website that aligns with your brand identity and business goals. Our solutions offer a seamless user experience, intuitive navigation, and robust functionality to maximize conversions. From custom design to optimized checkout processes, we ensure your online store stands out and meets customer expectations.

</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Platform Expertise


 </h4>

                        <p className=' mainTextP'>We specialize in building e-commerce stores on platforms like Shopify, WooCommerce, Magento, and BigCommerce. Each platform offers distinct advantages, and we help you choose the one that suits your needs best. With customized themes, third-party integrations, and scalable solutions, we ensure your e-commerce site is optimized for growth and profitability.


</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Mobile-Friendly Designs
 </h4>

                        <p className=' mainTextP'>Capture mobile shoppers with responsive website designs that adapt to all screen sizes. Our mobile-optimized designs ensure fast loading speeds, user-friendly navigation, and seamless checkout experiences. A mobile-first approach increases engagement and reduces bounce rates, leading to higher conversion rates.

</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Payment Gateway Integration
 </h4>

                        <p className=' mainTextP'>Provide a secure and convenient payment experience for your customers. We integrate trusted payment gateways such as Stripe, PayPal, and Razorpay, supporting multiple payment methods. With features like one-click checkout, multi-currency support, and fraud prevention measures, we ensure safe and smooth transactions.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Inventory & Order Management
 </h4>

                        <p className=' mainTextP'>Efficiently manage your inventory and streamline order processing with our integrated systems. Our automated solutions track inventory levels in real-time, prevent stockouts, and provide order tracking updates. By reducing manual efforts, you can focus on growing your business while maintaining exceptional customer service.





</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Multi-Vendor Marketplace Development
 </h4>

                        <p className=' mainTextP'>Expand your business with a multi-vendor marketplace platform where multiple sellers can showcase their products. Our scalable solutions include vendor management portals, secure payment processing, and automated commission tracking. Offer customers a diverse shopping experience while increasing your revenue streams.





</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        SEO & Marketing Support
 </h4>

                        <p className=' mainTextP'>Increase your online visibility with our comprehensive SEO and digital marketing services. We conduct in-depth keyword research, optimize product pages, and create engaging content to drive organic traffic. Additionally, our paid advertising campaigns, social media marketing, and email strategies help attract, convert, and retain customers, ensuring continuous growth.







</p>  
                        </div>
                                     </Col>
                    </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default Ecommerce;