import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
 import OnlyContactform from '../../components/section/onlyContactform';
 import { NavLink } from 'react-router-dom';

 class DesignSidebar extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
  <div class="theme-sidebar-one py-5">
							<div class="sidebar-box service-categories">
 								<ul>
								
									<li> <NavLink to="/website-development-company"   activeclassname="active">Website Design </NavLink></li>
									<li> <NavLink to="/e-commerce-website-development-company"   activeclassname="active">Ecommerce Website  </NavLink></li>
									<li> <NavLink to="/crm-cms-development-company"   activeclassname="active">CRM/CMS Development </NavLink></li>
									<li> <NavLink to="/mobile-app-development-company"   activeclassname="active">Mobile App Development</NavLink></li>
									<li> <NavLink to="/graphic-designing-company"   activeclassname="active">Graphic Design</NavLink></li>
									 
								</ul>
							</div> 
							 
							<div class="sidebar-box sidebar-contact my-5 p-3 bg-white">
								 <OnlyContactform/>
							</div>  
						</div>
             
     );
} 
}
export default DesignSidebar;