import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import {Fade} from 'react-awesome-reveal'
import { Typewriter } from 'react-simple-typewriter'
import { NavLink } from 'react-router-dom';

class WhyHire extends Component {

    render(){
        return(

            <>
            <div className='ServiceHeading aboutDiv AboutUs'  style={{ 
                backgroundImage: `url(/assets/images/bicads-images/our-service/BG.jpg)` 
              }}>
                <Container className=" py-5   text-center">

                <Row className=" pt-2 ">
                    <Col lg={{span:10 , offset:1}}>
                <h1 className='mainTextH1'>Who we are </h1>

            <h4 className='px-5 '>Helping Businesses Build & Maintain Their Online Presence</h4>

           <p> At Bicads Digital, we’re a team of experts committed to helping your business grow online. We specialize in creating user-friendly websites, eye-catching designs, and boosting your SEO to increase your visibility. Our paid ad strategies are tailored to drive targeted traffic and deliver measurable results.
</p>
 <p>We also help you connect with your audience through social media, building strategies that grow engagement and reach. Plus, we create engaging videos that tell your brand’s story in a way that resonates.</p>
 <p>With a data-driven approach, we focus on delivering real results, ensuring your business not only stands out but thrives in the digital space.
</p>
<NavLink to="/about-us"   activeclassname="active" ><button type="button" class="btn btn-primary btn-md  draw mt-3">Read More</button></NavLink>

</Col>
                </Row>           
                </Container>
            </div>
{/* --------------------------------------------------------- */}
            <div className='shipSection text-center '>
            <Container className='py-5'>
                <Row>
                    <Col lg='3' sm="6" xs="6">  
                    <Image src="/assets/images/bicads-images/about-page/Client-icon.png" alt={config.SITE_NAME}  fluid/>                            
                        <h4>300+ <p>Clients</p></h4> </Col>
                    <Col lg='3' sm="6"  xs="6">  
               <Image src="/assets/images/bicads-images/about-page/Keyword-icon.png" alt={config.SITE_NAME}  fluid/>                            

                       <h4>12000+ <p>keywords ranked</p></h4></Col>
                    <Col lg='3' sm="6"  xs="6"> 
               <Image src="/assets/images/bicads-images/about-page/Leads-icon.png" alt={config.SITE_NAME}  fluid/>                            

                       <h4>50000+  <p>leads generated</p></h4></Col>
                    <Col lg='3' sm="6"  xs="6"> 
               <Image src="/assets/images/bicads-images/about-page/ad-budget-icon.png" alt={config.SITE_NAME}  fluid/>                            

                       <h4>10 Million+  <p>spent on ads budget</p></h4></Col>
                </Row>
            </Container>
        </div>
            <div className='whyHireDiv'   style={{ 
                backgroundImage: `url(/assets/images/bicads-images/our-service/BG.jpg)` 
              }}>
                <Container className=" py-5   text-center">


                <h2 > Why Hire us </h2>

                <div className='imageText my-3'> 

                <Image src="/assets/images/bicads-images/why-hire/Search-Bar.png" alt={config.SITE_NAME} fluid  rounded/>
                <h4 className='textWords'>Efficient & Consistent With    <span style={{ color: '#01a5fe', fontWeight: '600' }}>
          {/* Style will be inherited from the parent element */}
          <Typewriter
            words={['Specific', 'Measurable', 'Achievable', 'Relevant','Time-bound']}
            loop={false}
            cursor
            cursorStyle=''
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            />
        </span></h4>

              
                </div>

                <p>SMART enough to choose the right actions!  <br></br>Scale new heights with our digital marketing services.</p>  


                </Container>


                <div className='textDiv'>  <h1 className='mainTextH1 '>    </h1>
              </div>
                <Container className=" pb-5 text-center">
            
                 
               <Row className="  ">

               <Col lg={{span:2, offset:1}} >   
               <Fade direction='up'  delay="40" triggerOnce="true">
                    <Image src="assets/images/bicads-images/why-hire/Define.png"  fluid /> 
                    <h5>Specific</h5>  
                    </Fade>
                    </Col>
                <Col lg='2' >  
                               <Fade direction='up'  delay="80" triggerOnce="true">
          
                <Image src="assets/images/bicads-images/why-hire/Measure.png"  fluid /> 
                <h5>Measurable</h5>  
  
</Fade>                </Col>
                <Col lg='2'>
                                   <Fade direction='up'  delay="120" triggerOnce="true">

                <Image src="assets/images/bicads-images/why-hire/Analyze.png"  fluid /> 
                <h5> Achievable</h5>  
  
</Fade>                </Col>
                <Col lg='2'>
                                   <Fade direction='up'  delay="160" triggerOnce="true">

                <Image src="assets/images/bicads-images/why-hire/Improve.png"  fluid />
                <h5>Relevant</h5>  
   
</Fade>                </Col>
                <Col lg='2'>
                                   <Fade direction='up'  delay="200" triggerOnce="true">

                <Image src="assets/images/bicads-images/why-hire/Control.png"  fluid />  
                <h5>Time-bound</h5>  
 
</Fade>                </Col>
               </Row>
             

                      

                {/* <Row className='py-3' >
                        <Col lg="6">

                        <p>The best marketing is the one that’s automated. This is the reason why many businesses are turning to digital marketing agencies. We can handle the entire process from start to finish. We got the experience and knowledge to make it work.

</p>

<p>Furthermore, we will work with your team to create a seamless experience and get solutions that are optimized for your target audience. In the end, you will get results that are consistent and effective with our digital marketing services.

</p>
                           </Col>
                       <Col lg="6">

                       <Image src="/assets/images/bicads/why-we-are-constant.png" alt={config.SITE_NAME} fluid  rounded/>

                                              </Col>
                    </Row> */}
           

                    
                </Container>
            </div>
            </>
        );
    }

}

export default WhyHire;