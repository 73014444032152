import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class PayPerClick extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title> PPC – Pay Per Click Company | Agency</title>
	<meta name="description" content="We are a PPC company that can help you set up campaigns that target the specific interests of your ideal customers via Google AdWords or Facebook Ads." />
	<link rel="canonical" href="https://bicads.com/ppc-marketing-company" />

   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Pay-Per-Click' subtitle='Pay-Per-Click' BkImg='/assets/images/bicads/Banner/PPC.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Pay-Per-Click</h3>

            <h2 className='px-5 '>Sky Rocket Your Business With Quality Leads</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-4'>
                        <h1 className='mainTextH4'>PPC – Pay Per Click Marketing Services In Pune and Mumbai (India)

</h1>

                        <p className=' mainTextP'>Instant Traffic, Maximum ROI – Supercharge Your Business with PPC! Want immediate leads, increased sales, and high-converting traffic for your business? Pay-Per-Click (PPC) advertising is the fastest way to put your brand in front of the right audience. At BicAds, we create high-performance PPC campaigns that deliver maximum ROI, whether you’re looking for Google Ads, Facebook Ads, Instagram Ads, or LinkedIn Ads.

</p>  

                          

                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/PPC.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Grow your business without the expensive upfront costs


</h2>

            <p>We can help you set up campaigns that target the specific interests of your ideal customers via Google AdWords or Facebook Ads. We can help you optimize your website's content to receive more organic traffic from Google and Facebook by identifying keyword and keyword groupings that your target customers are likely to type into Google or Facebook search boxes. We can also set up PPC campaigns with Google Shopping and Facebook Commerce. By identifying products that your target customers are likely to search for on Google and Facebook, we can help you generate more traffic to your website and increase your website conversion rate. If you have a physical retail store, you may also be able to set up a Google Merchant Center AdWords campaign to generate more offline foot traffic.</p>
 

  
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Google Ads (Search & Display)
 </h4>

                        <p className=' mainTextP'>Enhance your brand’s visibility with powerful Google Ads campaigns. Our customized search and display ads are designed to position your business at the top of search engine results. By using effective keywords, engaging visuals, and persuasive ad copy, we drive high-quality traffic to your website. Continuous performance analysis ensures your ads deliver maximum ROI.

</p>  
                        </div>
                                     </Col>
                   

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Social Media Ads

 </h4>

                        <p className=' mainTextP'>Boost your online presence with targeted social media ad campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter. We create platform-specific content that captures attention and sparks engagement. With precise audience targeting, captivating visuals, and data-driven strategies, we help you generate leads, increase conversions, and expand your brand reach.



</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Remarketing & Retargeting
 </h4>

                        <p className=' mainTextP'>Re-engage potential customers with our dynamic remarketing and retargeting campaigns. By delivering personalized ads to users who have interacted with your brand before, we bring them back to complete their journey. Our data-backed strategies ensure higher conversions by keeping your brand top-of-mind, encouraging repeat visits and purchases.

</p>  
                        </div>
                                     </Col>
                  

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>E-Commerce PPC (Shopping Ads)
 </h4>

                        <p className=' mainTextP'>Accelerate your online sales with optimized e-commerce PPC campaigns. Our Google Shopping Ads and product listing ads (PLAs) are tailored to showcase your products to the right audience. By using high-converting keywords, attractive product images, and competitive pricing, we drive qualified traffic to your store, leading to increased sales and revenue growth.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Local PPC & Geo-Targeting
 </h4>

                        <p className=' mainTextP'>Reach your ideal customers with localized PPC campaigns that deliver results. Whether your business operates in Pune, Mumbai, or other regions, we use geo-targeting to ensure your ads reach the most relevant audience. By tailoring content with local insights and keywords, we drive foot traffic, inquiries, and conversions for your business.
 
</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        PPC Audit & Optimization
 </h4>

                        <p className=' mainTextP'>Get the most out of your existing PPC campaigns with our detailed audits and optimization services. We identify areas of improvement by analyzing ad performance, keyword effectiveness, and budget allocation. With real-time adjustments and A/B testing, we reduce your ad spend while increasing click-through rates (CTR) and conversions. Our goal is to maximize your return on investment.


 
</p>  
                        </div>
                                     </Col>

                                     
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default PayPerClick;