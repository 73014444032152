import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class ContentMarketing extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Content Marketing Services in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content="Grow your brand with BicAds Digital! Get SEO-optimized blogs, web content & social media posts in Pune & Mumbai. Drive traffic & boost conversions today!" />
	<link rel="canonical" href="https://bicads.com/content-marketing-services" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Content Marketing' subtitle='Content Marketing' BkImg='/assets/images/bicads/Banner/Content-Marketing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Content Marketing</h3>

            <h2 className='px-5 '>Right Content For Better Business Communication</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-3'>
                        <h1 className='mainTextH4'>Content Marketing Services In Pune and Mumbai (India)


</h1>

                        <p className=' mainTextP'>Struggling to capture your audience’s attention? In today’s fast-paced digital world, content is the game-changer that sets your brand apart. Whether you’re a startup or an established business in Pune or Mumbai, your content needs to engage, inform, and convert. At BicAds, we don’t just create content—we craft compelling stories that build trust, drive traffic, and turn leads into loyal customers.

</p> 

                          <p className=' mainTextP'>Did you know? Brands with a strong content marketing strategy generate 3X more leads than those without!

</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Content-Marketing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Developing Content To Grow Your Business



</h2>

            <p>We begin by determining what your audience should be able to do after reading your content. We then produce blog posts, infographics, podcasts, videos, and other marketing materials that help you achieve your goals.</p>
 
                </Container>
            </div>
            {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Content Strategy & planning
 </h4>

                        <p className=' mainTextP'>We create a personalized content roadmap designed to align with your business objectives, ensuring each piece of content drives value and supports your growth. Our strategic approach focuses on maximizing audience engagement and delivering a high return on investment. By conducting in-depth market research and competitor analysis, we develop relevant, impactful content that strengthens your brand presence and keeps you ahead in the digital landscape.

</p>  
                        </div>
                                     </Col>
                 
                     

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Blog Writing & Articles

 </h4>

                        <p className=' mainTextP'>Strengthen your online presence and improve search engine rankings with our expertly crafted, informative blogs. Designed to establish your brand as a thought leader, our content offers valuable insights that engage and inform your audience. By integrating relevant keywords and using storytelling techniques, we enhance your brand’s visibility, drive organic traffic, and build lasting connections with your target audience.



</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Website Content
 </h4>

                        <p className=' mainTextP'>We deliver persuasive, SEO-optimized content designed to convert visitors into paying customers. By crafting clear and compelling messages, we build trust and establish your brand’s authority. From attention-grabbing homepages to informative product pages, every piece of content is strategically written to engage your audience, enhance user experience, and drive higher conversions. Our focus on relevant keywords and customer-centric storytelling ensures your business stands out in search results and resonates with potential buyers.

</p>  
                        </div>
                                     </Col>
                     
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Social Media Content

 </h4>

                        <p className=' mainTextP'>We design captivating captions, engaging posts, and result-driven campaigns that initiate conversations and boost audience interaction. Our platform-specific content is tailored to align with your brand voice and connect with your target audience. By incorporating visually appealing graphics, relatable messaging, and compelling calls-to-action, we enhance your brand’s visibility, drive meaningful engagement, and maximize your social media presence.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Video Scripts & Storytelling </h4>

                        <p className=' mainTextP'>We create impactful scripts that vividly showcase your brand's story, capturing your audience's attention through emotion-driven storytelling. By seamlessly blending authentic brand messaging with compelling narratives, our scripts are designed to resonate with viewers on a deeper level. Whether it's for promotional videos, social media content, or advertisements, our storytelling approach drives views, encourages shares, and enhances brand recognition, leaving a lasting impression on your audience.</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Email & Newsletter Marketing </h4>

                        <p className=' mainTextP'>We craft personalized email campaigns designed to capture your audience’s attention and keep them engaged. With compelling subject lines and relevant content, we optimize your emails to boost open rates and drive higher click-throughs. Our strategically timed newsletters provide valuable updates, insights, and promotions, ensuring your brand remains top-of-mind. By delivering content tailored to your audience’s interests, we strengthen customer relationships, enhance brand loyalty, and maximize your email marketing ROI.

</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        Case Studies & Whitepapers
 </h4>

                        <p className=' mainTextP'>We help you establish your brand’s authority with well-researched, insightful content that resonates with your audience. Our detailed case studies showcase real-world success stories, demonstrating the value and impact of your products or services. Additionally, our expertly crafted whitepapers provide in-depth analysis and actionable insights, positioning your brand as a trusted industry leader. By leveraging data-driven storytelling and thought leadership content, we enhance your credibility, build trust, and engage your target audience effectively.



</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}

             <Footer/>
        </div>
    );
} 
}
export default ContentMarketing;