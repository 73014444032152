import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
 import OnlyContactform from '../../components/section/onlyContactform';
 import { NavLink } from 'react-router-dom';

 class DesignSidebar2 extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
  <div class="theme-sidebar-one py-5">
							<div class="sidebar-box service-categories">
 								<ul>
                                 <li>  <NavLink to="/social-media-marketing-company"   activeclassname="active">Social Media Marketing</NavLink></li>
									<li>  <NavLink to="/search-engine-optimization-company"   activeclassname="active">Search Engine Optimization</NavLink></li>
									<li>  <NavLink to="/ppc-marketing-company"   activeclassname="active">Pay-Per-Click</NavLink></li>
									<li> <NavLink to="/content-marketing-services"   activeclassname="active">Content Marketing</NavLink></li>
									{/* <li>                 <NavLink to="/branding-services"   activeclassname="active">Branding Services</NavLink></li>
									<li>  <NavLink to="/digital-pr"   activeclassname="active">Digital PR</NavLink></li> */}
                                  
                                    <li>   <NavLink to="/wikipedia-page-creation-service"   activeclassname="active">Wikipedia Page Creation</NavLink></li>
									 
								</ul>
							</div> 
							 
							<div class="sidebar-box sidebar-contact my-5 p-3 bg-white">
								 <OnlyContactform/>
							</div>  
						</div>
             
     );
} 
}
export default DesignSidebar2;