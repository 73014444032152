import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class Crm extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>CRM & CMS Development in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content="Get custom CRM & CMS development with Bicads Digital in Pune & Mumbai. Automate workflows and manage content seamlessly to enhance efficiency & growth." />
	<link rel="canonical" href="https://bicads.com/crm-cms-development-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='CRM/CMS ' subtitle='Development' BkImg='/assets/images/bicads/Banner/CRM.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> CRM/CMS Development </h3>

            <h2 className='px-5 '>Centrally Manage Your Business. Never Miss A Lead.<br></br> Ensure Your Customers Are Happy Always
   Take It Online With Us!!</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" py-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond'>
                        <h1 className='mainTextH4'>CRM/CMS Development Company In Pune and Mumbai (India)

 </h1>

                        <p className=' mainTextP'>Empower Your Business with Custom CRM and CMS Solutions

</p> 

                        <p className=' mainTextP'>Looking to streamline your business operations or enhance your online presence? Our CRM (Customer Relationship Management) and CMS (Content Management System) development services in Pune and Mumbai are designed to meet your unique business needs. From automating workflows to managing digital content effortlessly, we provide end-to-end solutions tailored for you

</p> 
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/CRM.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  CRM</h3>

            <h2 className='px-5 '>Your CRM should work for you, not the other way around</h2>

            <p>Your custom software should be adapted to match your CRM in order to serve as an extension of your business. That's why we offer a variety of CRM options that are tailored to your particular business requirements when building software. From healthcare providers to manufacturing companies, restaurants to retail outlets, there are CRMs for all types of businesses.

</p>

 
            <Row className='goodWebsite  d-none'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
           {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                     
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Custom CRM Development
 </h4>

                        <p className=' mainTextP'>Boost your business efficiency with a personalized Customer Relationship Management (CRM) system designed to streamline customer interactions. Our tailored solutions help you manage leads, automate sales workflows, and gain actionable insights. With real-time data tracking and user-friendly dashboards, you can enhance customer satisfaction, improve sales performance, and optimize your overall business operations.

</p>  
                        </div>
                        </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>CMS Development

 </h4>

                        <p className=' mainTextP'>Easily manage and control your digital content with our scalable Content Management System (CMS) development services. Whether you run a blog, corporate website, or e-commerce platform, our solutions ensure smooth content updates and organization. We create SEO-friendly, responsive CMS platforms that enhance your online presence, engage your audience, and drive organic traffic.



</p>  
                        </div>
                                     </Col>
                    

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>CRM Integration
 </h4>

                        <p className=' mainTextP'>Simplify operations with our seamless CRM integration services. We connect your CRM with essential tools such as ERP systems, marketing automation platforms, and third-party applications. This ensures synchronized data flow, improved team collaboration, and enhanced customer insights. Experience increased productivity and smarter decision-making with our customized integration solutions.

</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>E-commerce Solutions
 </h4>

                        <p className=' mainTextP'>Accelerate your online business growth with robust e-commerce solutions using platforms like Shopify, Magento, and WooCommerce. Our e-commerce CMS systems offer a seamless shopping experience with secure payment gateways, responsive designs, and user-friendly navigation. Drive more sales with optimized product listings, personalized recommendations, and intuitive customer journeys.



</p>  
                        </div>
                                     </Col>

                                     
                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Support & Maintenance
 </h4>

                        <p className=' mainTextP'>Keep your CRM or CMS running smoothly with our dedicated support and maintenance services. From regular updates and security enhancements to performance monitoring and bug fixes, we ensure your platform remains reliable and efficient. Our proactive support team works to minimize downtime, improve functionality, and maintain an exceptional user experience.





</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
    
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default Crm;