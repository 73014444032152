import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class SearchEngine extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>SEO Company in Pune & Mumbai | Bicads Digital</title>
	<meta name="description" content=": Improve your website ranking with expert Search Engine Optimization Company in Pune & Mumbai. Rank higher and get more leads." />
	<link rel="canonical" href="https://bicads.com/search-engine-optimization-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Search Engine Optimization' subtitle='Search Engine Optimization' BkImg='/assets/images/bicads/Banner/SEO.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Search Engine Optimization</h3>

            <h2 className='px-5 '>Driving more traffic to your website by increasing rank using search engine optimization
</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-4'>
                        <h1 className='mainTextH4'>SEO – Search Engine Optimization Company In Pune and Mumbai (India)
</h1>

                        <p className=' mainTextP'>Imagine generating a steady flow of qualified leads without overspending on ads. That’s the power of smart Search Engine Optimization (SEO)!


</p>  

<p className=' mainTextP'>Did you know that 57% of B2B marketers believe SEO generates more leads than any other strategy? With a 14.6% close rate, SEO outperforms outbound marketing methods significantly. Unlike Pay-Per-Click (PPC) advertising, where you pay for every visitor, SEO helps your website rank naturally, driving long-term growth without ongoing costs.

</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/SEO.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h1 className='mainTextH1'>How It Works?</h1>

            <h2 className='px-5 '>How SEO Will Benefit You

</h2>

            <p>SEO is a method for elevating a website's ranking on search engine results pages without paying for advertisement clicks. Those who invest in SEO typically outrank the competition and gain market share. SEO can help you attract anyone who has interest in a purchase at any point in the purchasing funnel, no matter what stage of the consumer's purchasing funnel they are in.
</p>

                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                            
                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>
                        On-Page SEO Optimization
 </h4>

                        <p className=' mainTextP'>Enhance your website’s search visibility with our tailored on-page SEO services. We improve your site’s loading speed, optimize its structure, and refine content to align with search engine algorithms. By using targeted keywords, optimizing meta descriptions, and ensuring mobile responsiveness, we ensure your website ranks higher and provides an excellent user experience.

</p>  
                        </div>
                                     </Col>
                     
                     
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Off-Page SEO (Backlink Building)

 </h4>

                        <p className=' mainTextP'>Strengthen your website’s authority through our strategic backlink-building services. We acquire high-quality backlinks from relevant, trustworthy websites to boost your domain authority. Our ethical and effective link-building methods improve your search engine rankings, increase referral traffic, and enhance your brand’s credibility across search platforms.


</p>  
                        </div>
                                     </Col>
                    
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Content Optimization & Blogging
 </h4>

                        <p className=' mainTextP'>Drive organic traffic with SEO-optimized content that resonates with your target audience. Our content experts create informative, engaging blogs and articles using well-researched keywords. We ensure every piece is structured for both readers and search engines, using keyword placement, proper headings, and internal linking to maximize visibility and authority in your industry.

</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Local SEO & Google My Business (GMB)
 </h4>

                        <p className=' mainTextP'>Boost your local presence with our specialized local SEO strategies. We optimize your Google My Business (GMB) profile, ensuring your business stands out in local searches. From accurate NAP (Name, Address, Phone Number) details to managing customer reviews, we help you rank higher in local search results. Our geo-targeted keyword strategies are ideal for dominating markets in Pune, Mumbai, and surrounding areas.



</p>  
                        </div>
                                     </Col>

                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>E-Commerce SEO
 </h4>

                        <p className=' mainTextP'>Maximize your e-commerce store’s potential with our specialized e-commerce SEO solutions. We optimize product descriptions, categories, and images using relevant keywords to improve search rankings. Through technical optimization, schema markups, and mobile-friendly design, we ensure your products attract the right customers and drive sales.




</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Keyword Research & Strategy
 </h4>

                        <p className=' mainTextP'>Achieve better search engine rankings with our comprehensive keyword research and strategy. We analyze search intent, competition, and industry-specific keywords to create a customized keyword plan. Whether you aim to rank for high-volume terms or long-tail keywords, our strategies ensure increased visibility and qualified website traffic.






</p>  
                        </div>
                                     </Col>


                                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>SEO Audit & Competitor Analysis
 </h4>

                        <p className=' mainTextP'>Identify growth opportunities with our in-depth SEO audits and competitor analysis. We examine your website’s technical health, on-page and off-page factors, and backlink profile. Our detailed reports provide actionable insights to resolve issues, improve your website’s performance, and outperform competitors. With continuous optimization, we help your brand achieve long-term search engine success.








</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}



             <Footer/>
        </div>
    );
} 
}
export default SearchEngine;